import Service from '@/services/Service'

class ExchangeService extends Service {
  constructor() {
    super('EXCHANGE')
    this.resource = 'mbcommerce/exchange'
  }

  createRequestUrl(query) {
    let requestUrl = `${this.resource}?`

    if (query) query = this.encodeQueryValues(query)

    if (query.todos)
      requestUrl += this.hasParameter(requestUrl)
        ? `&TODOS=${query.todos}`
        : `TODOS=${query.todos}`
    else
      requestUrl += this.hasParameter(requestUrl)
        ? `&page=${query.page}`
        : `page=${query.page}`

    if (query.filtro)
      requestUrl += this.hasParameter(requestUrl)
        ? `&filtro=${query.filtro}`
        : `filtro=${query.filtro}`
    return requestUrl
  }

  async getExchanges(payload) {
    let requestUrl = `${this.resource}`
    const { data } = await this._http.post(requestUrl, payload)
    return data
  }

  async getExchangeOrder(payload) {
    let requestUrl = `${this.resource}-order`
    const { data } = await this._http.post(requestUrl, payload)
    return data
  }
}

export default new ExchangeService()
