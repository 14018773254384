import Service from '../Service'
class ItemService extends Service {
  constructor() {
    super()
    this.resource = 'items'
  }

  async getItems(query = null) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }

  async getSelectedItem(payload) {
    let requestUrl = `${this.resource}/getSelected`
    const { data } = await this._http.post(requestUrl, payload)
    return data
  }

  async getItem(payload, query) {
    let requestUrl = `/${this.resource}/${payload}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }

  async getItemImages(payload, query) {
    let requestUrl = `/${this.resource}/getImages/${payload}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }
}

export default new ItemService()
