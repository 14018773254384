import service from '@/services/states/StateService'

export const stateModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    availableStates: [],
  },
  mutations: {
    SET_STATES(state, payload) {
      state.availableStates = payload
    },
  },

  actions: {
    async getStates({ state, commit }, payload) {
      try {
        if (state.availableStates.length >= 1) return
        const { data } = await service.getStates(payload)
        commit('SET_STATES', data)
      } catch (error) {
        console.error(error)
      }
    },
  },

  getters: {
    availableStates: (state) => {
      return state.availableStates
    },
  },
}
