import axios from 'axios'
import http from '@/services'

export const validaRUC = function (valor, basemax = 11) {
  valor = valor.replace(/[^0-9]+/g, '')
  let result = false
  if (valor.length == 0) return true // RUC não é mais obrigatorio pro py, caso não tenha nada, não bloqueia o campo.

  let numero_al = ''

  let genericRucList = [
    // lista de ruc genericos
    '22222201',
    '66666601',
    '77777701',
    '88888801',
    '99999901',
  ]
  if (process.env.VUE_APP_NODE_ENV != 'local') genericRucList.push('44444401')
  if (genericRucList.includes(valor.substring(0, valor.length - 1)))
    // se o RUC - DV for igual a algum da lista, bloqueia.
    return result
  for (let i = 0; i < valor.length; i++) {
    let c = valor[i]

    // faz a substituição de letra por codigo ascii
    let codigo = c.charCodeAt(0)
    if (!(codigo >= 48 && codigo <= 57)) {
      //{ entre 0 e 9 }
      numero_al = `${numero_al}${codigo}`
    } else {
      numero_al = `${numero_al}${c}`
    }
  }

  let k = 2
  let total = 0
  for (let i = numero_al.length - 1; i > 0; i--) {
    if (k > basemax) k = 2
    let numero_aux = parseInt(numero_al[i - 1])
    total += numero_aux * k
    k++
  }

  let resto = total % basemax

  let digito = 0
  if (resto > 1) digito = basemax - resto

  let calculo = numero_al[numero_al.length - 1]

  result = digito == calculo

  return result
}

export const verifyURL = function () {
  const hostname = window.location.hostname
  // 10.0.0.0 - 10.255.255.255
  // 172.16.0.0 - 172.31.255.255
  // 192.168.0.0 - 192.168.255.255

  const arrHostname = hostname.split('.')
  if (
    arrHostname[0] == 'localhost' ||
    arrHostname[0] == 10 ||
    (arrHostname[0] == 172 && arrHostname[1] >= 16 && arrHostname[1] < 32) ||
    (arrHostname[0] == 192 && arrHostname[1] == 168)
  )
    return process.env.VUE_APP_BASEURL || 'http://127.0.0.1:8000/api/'

  return process.env.VUE_APP_BASEURL_EXTERNAL
}

export const validaCpfCnpj = function (val) {
  if (!val) return false

  val = val.trim()
  val = val.replace(/\./g, '')
  val = val.replace('-', '')
  val = val.replace('/', '')
  val = val.split('')

  if (val.length == 11) {
    let cpf = val
    let v1 = 0
    let v2 = 0
    let aux = false
    let i = 0
    let p = 0

    for (i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true
      }
    }

    if (aux == false) {
      return false
    }

    for (i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p
    }

    v1 = (v1 * 10) % 11

    if (v1 == 10) {
      v1 = 0
    }

    if (v1 != cpf[9]) {
      return false
    }

    for (i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p
    }

    v2 = (v2 * 10) % 11

    if (v2 == 10) {
      v2 = 0
    }

    if (v2 != cpf[10]) {
      return false
    } else {
      return true
    }
  } else if (val.length == 14) {
    let cnpj = val
    let v1 = 0
    let v2 = 0
    let aux = false
    let i = 0
    let p1 = 0
    let p2 = 0

    for (i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true
      }
    }

    if (aux == false) {
      return false
    }

    for (i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1
      } else {
        v1 += cnpj[i] * p2
      }
    }

    v1 = v1 % 11

    if (v1 < 2) {
      v1 = 0
    } else {
      v1 = 11 - v1
    }

    if (v1 != cnpj[12]) {
      return false
    }

    for (i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1
      } else {
        v2 += cnpj[i] * p2
      }
    }

    v2 = v2 % 11

    if (v2 < 2) {
      v2 = 0
    } else {
      v2 = 11 - v2
    }

    if (v2 != cnpj[13]) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export const formatMaskDocumento = (value, mask) => {
  if (!value) return ''

  var toMask = value.replace(/[^0-9]+/g, '')
  const mascara = mask
  let result = ''
  let countTerm = 0

  for (let index = 0; index < mascara.length; index++) {
    if (!toMask[index - countTerm]) {
      break
    } else if (
      mascara[index] == '.' ||
      mascara[index] == '-' ||
      mascara[index] == '/'
    ) {
      result += mascara[index]
      countTerm++
    } else {
      result += toMask[index - countTerm] ?? ' '
    }
  }

  return result
}

export const formatMaskCEP = (value, mask = '##.###-###') => {
  if (!value) return ''

  var toMask = value.replace(/[^0-9]+/g, '')
  const mascara = mask
  let result = ''
  let countTerm = 0

  for (let index = 0; index < mascara.length; index++) {
    if (!toMask[index - countTerm]) {
      break
    } else if (mascara[index] == '.' || mascara[index] == '-') {
      result += mascara[index]
      countTerm++
    } else {
      result += toMask[index - countTerm] ?? ' '
    }
  }
  return result
}

export const clearMaskCEP = (value) => {
  if (!value) return ''
  let result = value.replace(/[^0-9]+/g, '')
  return result
}

export const formatMaskDate = (value, mask = '##/##/####') => {
  if (!value) return ''

  var toMask = value.replace(/[^0-9]+/g, '')
  const mascara = mask
  let result = ''
  let countTerm = 0

  for (let index = 0; index < mascara.length; index++) {
    if (!toMask[index - countTerm]) {
      break
    } else if (mascara[index] == '/' || mascara[index] == '-') {
      result += mascara[index]
      countTerm++
    } else {
      result += toMask[index - countTerm] ?? ' '
    }
  }
  return result
}

export const eventKeyPress = ($event) => {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which
  if (
    keyCode == 13 || // Enter
    keyCode == 43 || // +
    keyCode == 45 || // -
    keyCode == 46 || // .
    keyCode == 69 || // e
    keyCode == 101 // E
  ) {
    return $event.preventDefault()
  } else return $event.key
}

export const dateValidation = (isRequired = false) => {
  // eslint-disable-next-line
  let regex =
    /^(?:(?:31([-\/.]?)(?:0[13578]|1[02]))\1|(?:(?:29|30)([-\/.]?)(?:0[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29([-\/.]?)02\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26]))))$|^(?:0[1-9]|1\d|2[0-8])([-\/.]?)(?:(?:0[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/

  return (v) => {
    if (!v) {
      if (isRequired) return 'Campo Obrigatório.'
      // i18n.t('validation-messages.requiredField')
      return true
    }
    return (v && regex.test(v)) || 'Data inválida.'
    // i18n.t('validation-messages.dataInvalida')
  }
}

export const convertImage = (image) => {
  if (image) {
    var bytes = new Uint8Array(image)
    var binary = bytes.reduce((data, b) => (data += String.fromCharCode(b)), '')
    image = 'data:image/jpeg;base64,' + Buffer.from(binary, 'base64')
  }
  return image
}

export const consultaViaCep = async (cep) => {
  cep = cep.replace(/[^0-9]+/g, '')
  const requestUrl = 'https://viacep.com.br/ws/' + cep + '/json/'
  try {
    const { data } = await axios.get(requestUrl)
    return data
  } catch (e) {
    console.error('erro viaCep', e)
    if (e.code === 'ERR_NETWORK')
      throw 'Verifique se os dados informados para consulta estão corretos!'
    throw e
  }
}

export const consultaCepRepublicaVirtual = async (cep) => {
  cep = cep.replace(/[^0-9]+/g, '')
  const requestUrl =
    'http://republicavirtual.com.br/web_cep.php?cep=' + cep + '&formato=jsonp'
  try {
    const { data } = await axios.get(requestUrl)
    return data
  } catch (e) {
    throw e
  }
}

export const requiredField = (propertyType, customErrorMessage) => {
  return (v) =>
    !!v || customErrorMessage || `${propertyType} ${'Campo obrigatório.'}.`
}

export const emailFormat = (isRequired = false) => {
  // eslint-disable-next-line
  let regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  return (v) => {
    if (!v) {
      if (isRequired) return 'Este campo é Obrigatório.'
      return true
    }
    return (v && regex.test(v)) || 'Formato do e-mail é inválido.'
  }
}

export const captalizeText = (text) => {
  let captalizeText = ''
  if (text)
    captalizeText = text
      .toLowerCase()
      .replace(/\b(\w)/g, (s) => s.toUpperCase())

  return captalizeText
}

export const minLength = (propertyType, minLength) => {
  return (v) => {
    if (!v) {
      return true
    }

    return (
      v.length >= minLength ||
      `${propertyType} Digite ao menos ${minLength} caracteres.`
    )
  }
}

export const maxLength = (propertyType, maxLength) => {
  return (v) =>
    (v && v.length <= maxLength) ||
    `${propertyType} Digite no máximo ${maxLength} caracteres.`
}

export const converterDataDB = (value) => {
  // yyyy-mm-dd -> dd/mm/yyyy
  if (!value) return
  let date = value.substring(0, 10).split('-')
  date = date[2] + '/' + date[1] + '/' + date[0]
  return date
}

export const formataDataBD = (ddmmyyyy) => {
  // dd/mm/yyyy -> yyyy-mm-dd
  if (!ddmmyyyy) return
  let date = ddmmyyyy.split(' ')[0].split('/')
  date = date[2] + '-' + date[1] + '-' + date[0]
  return date
}

export const converterDateTimePix = (datetime) => {
  const date = new Date(datetime).toLocaleDateString('pt-BR')
  const time = new Date(datetime).toLocaleTimeString('pt-BR')
  return date + ' ' + time
}

export const formatMaskPlanoContas = (value, mask) => {
  if (!value) return ''

  var toMask = value.replace(/[^0-9]+/g, '')
  const mascara = mask
  let result = ''
  let countTerm = 0

  for (let index = 0; index < mascara.length; index++) {
    if (!toMask[index - countTerm]) {
      break
    } else if (mascara[index] == '.') {
      result += mascara[index]
      countTerm++
    } else {
      result += toMask[index - countTerm] ?? ' '
    }
  }
  return result
}

export const formatMaskTelefone = (value, mask) => {
  if (!value) return ''

  const chars = '() -'
  var toMask = value.replace(/[^0-9]+/g, '')
  const mascara =
    mask?.length > 0
      ? mask
      : toMask.length == 11
      ? '(XX) XXXXX-XXXX'
      : '(XX) XXXX-XXXX'
  let result = ''
  let countTerm = 0

  for (let index = 0; index < mascara.length; index++) {
    if (!toMask[index - countTerm]) {
      break
    } else if (chars.lastIndexOf(mascara[index]) !== -1) {
      result += mascara[index]
      countTerm++
    } else {
      result += toMask[index - countTerm] ?? ' '
    }
  }
  return result
}

export const generatePassword = (passwordLength = 12) => {
  var chars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var password = ''

  for (var i = 0; i <= passwordLength; i++) {
    do {
      var randomNumber = Math.floor(Math.random() * chars.length)
    } while (randomNumber === password[i])
    password += chars.substring(randomNumber, randomNumber + 1)
  }

  return password
}

export const scrollPage = (id, topDegree = 0) => {
  var rec = document.getElementById(id).getBoundingClientRect()
  var pos = {
    top: rec.top + window.scrollY,
    left: rec.left + window.scrollX,
  }
  window.scroll({
    top: pos.top + topDegree,
    left: pos.left,
    behavior: 'smooth',
  })
}

/**
 * Métodos para manipular Local Storage
 */

export const verifyPropertyNameByContext = (prop) => {
  const viewContext =
    localStorage.getItem('viewContext') ||
    process.env.VUE_APP_INIT_VIEWCONTEXT ||
    'companyList'

  const idCompany =
    viewContext == 'companyList' ? localStorage.getItem('idCompanyList') : null

  const cartName = `${prop}.${viewContext}`

  return idCompany ? `${cartName}.${idCompany}` : cartName
}

export const getListFromStorage = (propName) => {
  return localStorage.getItem(propName)
    ? JSON.parse(localStorage.getItem(propName))
    : []
}

export const getObjectFromStorage = (propName) => {
  return localStorage.getItem(propName)
    ? JSON.parse(localStorage.getItem(propName))
    : null
}

/**
 *
 * @returns fake order agillitas (test only)
 */
export const getFakeOrderAgillitas = () => {
  return {
    status: 'Pedido gravado com sucesso. [58581]',
    order: {
      SEQUENCIAL: '3840018.0000',
      DEPARTAMENTO: null,
      DATAINCLUSAOALTERACAO: '2023-05-25 17:53:34',
      DATA: '2023-05-25 00:00:00',
      DATALIBERACAO: null,
      DATAENTREGA: null,
      DATAEMISSAO: '2023-05-25 00:00:00',
      PREVISAOENTREGA: null,
      VALOR: '54.90',
      PERCENTUALDESCONTO: '0.00000000000',
      PERCENTUALCOMISSAO: null,
      VALORDESCONTO: '0.00',
      ARREDONDAMENTODESCONTO: '0.00',
      VALORDESCONTO2: null,
      CODIGOCAIXA: null,
      NUMERO: 58581,
      NUMERONOTA: null,
      NUMEROCUPOM: null,
      SEQUENCIALNOTA: null,
      SEQUENCIALCUPOM: null,
      HORA: '17:53:35',
      SERIE: '01',
      MOEDA: '0',
      TIPO: 'Orçamento',
      TIPOPRECO: 'V',
      LIBERADO: 'P',
      TIPOLIBERACAO: '1',
      CODIGOUSUARIO: '113',
      CONDICAOPGTO: null,
      REFEITO: null,
      PRELIBERADO: 'S',
      DADOSPRELIBERACAO: '25/05/2023 17:53:34 CONVIDADU',
      FILIAL: 1,
      FILIALNOTA: null,
      PERCFISCAL: null,
      VALORMOEDAPRINCIPAL: null,
      VALORFISCAL: null,
      VALORTERCEIROS: '0.00',
      VALORACRESCIMOFINANCEIRO: null,
      VALORACRESCIMOCARTAO: null,
      USUARIOULTIMOMVTO: 'CONVIDADU',
      COMISSAOEXTERNA: null,
      PAGARCOMISSAOEM: '2023-05-25 00:00:00',
      ANO: 2023,
      VALORCARTAOCREDITO: null,
      VALORABATERDECOMISSAO: null,
      TOTALEMABERTO: 'S',
      DESCRICAOG: null,
      PLACA: null,
      KILOMETRAGEM: null,
      CND_OS: null,
      OBSERVACAO: null,
      AGRUPAMENTO: null,
      ORDEMITENS: null,
      CANCELA_REVERTE: null,
      USUARIO: 'CONVIDADU',
      MESA: null,
      INDICADO: null,
      OBRA: null,
      FASE: null,
      CT: null,
      ROMANEIO: null,
      DATAHORAROMANEIO: null,
      CONTALIBERACAO: null,
      CONTROLEENTREGA: null,
      NUMEROEXPORTADO: null,
      VALORCOMISSAO: null,
      ORIGEM: 'LOJA',
      GERADOPELAROTINA: 'Balcao_web',
      IMPRIMESEPARACAO: null,
      AGREGADO: null,
      DADOSCONFERENCIA: null,
      CODIGOFIDELIDADE: null,
      PDVMESA: null,
      PDVRESERVA: null,
      PDVTIPO: null,
      SEQUENCIALRECEITA: null,
      COR: null,
      ENTREGADOR: null,
      TIPOPREVISAOENTREGA: 'E',
      ENTREGUE: null,
      IDPDA: null,
      STATUS: ', Tipo Entrega:  Entrega',
      QUANTIDADETOTAL: '2.0000',
      QUANTIDADEORDEMENTREGATOTAL: '0.0000',
      VALORCOMISSAOEXTERNAINF: null,
      VALORFRETE: '15.0000',
      CAMBIO0: null,
      CAMBIO1: null,
      CAMBIO2: null,
      CAMBIO3: null,
      CAMBIO4: null,
      CAMBIO5: null,
      QUANTIDADETOTALSERVICOS: '0.0000',
      QNTENTREGUETOTAL: 'N',
      SEQUENCIALLISTA: null,
      PERCACRESCFINANCEIRO: null,
      NOMEDOCOMPRADOR: null,
      TOTALOUTROSACRESCIMOS: null,
      SEPARADOR: null,
      PESSOAS: null,
      VALORRETENCAO: null,
      DATARETENCAO: null,
      MOTIVOPERDA: null,
      ETAPAVENDA: null,
      DATAALTERACAOETAPAVENDA: null,
      BLOQUEIAALTERACAO: null,
      TEMPOSEPARACAO: null,
      DATAPREVISAOETAPAVENDA: null,
      PRAZOMEDIO: null,
      USUARIODATALIBERACAO: null,
      TIPOCARTAO: null,
      DATAENTREGACLIENTE: null,
      PRESENCIAL: null,
      USUARIOALTEROUPDV: null,
      LATITUDE: null,
      LONGITUDE: null,
      OUTRACONTALIBERACAO: null,
      SEQUENCIALMOVIMENTO: null,
      DATARETORNO: null,
      EMPRESANOTA: null,
      DATAINCLUSAO: '2023-05-25 17:53:35',
      CODIGOLIBERACAOSERVICO: null,
      SEQENDERECO: null,
      TRIBUTACAOEMPRESA: null,
      CAMBIOAGILLITAS: '5.25',
      empresa: {
        CODIGO: '4435',
        NOME: 'eder',
        RAZAOSOCIAL: 'eder',
        CGC: '03732145964',
        VENDEDOR: '113',
        DESCONTO: null,
        TIPOPRECO: null,
        CELULAR: null,
        BLOQUEADO: null,
        EMAIL: 'ederson_megabit@hotmail.com',
        FONE: '45984013609',
      },
      itens: [
        {
          DESCRICAO: null,
          SEQUENCIALORCAMENTO: '3840018.0000',
          SEQUENCIALITENNOTAIMP: null,
          SEQUENCIAL: 3840019,
          QUANTIDADE: '1.0000',
          QUANTIDADEPENDENTE: '0.0000',
          QUANTIDADEORDEMENTREGA: null,
          QUANTIDADESEPARADA: null,
          PRECOVAREJO: null,
          VALORABATERCOMISSAO: null,
          PRECOATACADO: null,
          PRECOESPECIAL: null,
          PRECOESPECIAL2: null,
          PRECOESPECIAL3: null,
          PRECO: '39.900',
          VALORTOTAL: '39.900',
          QUANTIDADEEMBALAGEM: null,
          PRECOEMBALAGEM: null,
          PRECOMOEDAPRINCIPAL: '39.900',
          PERCENTUALDESCONTO: null,
          VALORDESCONTO: null,
          VALORDESCONTOUNITARIO: null,
          TIPOPRECO: 0,
          NUMERO: 58581,
          SEQ: null,
          ITEM: '05902',
          SERIE: '01',
          MEDIDA: null,
          LIBERADO: 'S',
          TIPO: 'Orçamento',
          MARCADO: null,
          CUSTOMEDIO: null,
          OUTROSCUSTOS: '0.00',
          CODIGOUSUARIO: null,
          FILIAL: 1,
          FILIALENTREGA: null,
          OBRA: null,
          FASE: null,
          DATA: '2023-05-25 00:00:00',
          CONVERSOR: '1.0000',
          ITEMESTOQUE: '05902',
          COMPLEMENTO: null,
          COMPLEMENTODESCRICAO: 'AC LIKA NENE TIARA',
          EMPRESACODIGO: 4435,
          FILIALNF: 1,
          DEPARTAMENTO: null,
          OS: 'N',
          SOMOUEMTOTAL: 'S',
          VENDEDOR: null,
          LETRADESCONTO: null,
          EMOFERTA: null,
          CONTROLEENTREGA: null,
          LOTE: null,
          PDVQUANTIDADEREF: null,
          DESAGIO: null,
          SEQUENCIALLCTOVENDEDORES: null,
          DATAINCLUSAO: null,
          SEQUENCIALLISTA: '0.0000',
          PERCACRESCFINANCEIRO: null,
          VALORACRESCFINANCEIRO: null,
          VALOROUTROSACRESCIMOS: null,
          GRADELINHA: null,
          GRADECOLUNA: null,
          QUANTIDADEDEVOLUCAO: null,
        },
        {
          DESCRICAO: null,
          SEQUENCIALORCAMENTO: '3840018.0000',
          SEQUENCIALITENNOTAIMP: null,
          SEQUENCIAL: 3840020,
          QUANTIDADE: '1.0000',
          QUANTIDADEPENDENTE: '0.0000',
          QUANTIDADEORDEMENTREGA: null,
          QUANTIDADESEPARADA: null,
          PRECOVAREJO: null,
          VALORABATERCOMISSAO: null,
          PRECOATACADO: null,
          PRECOESPECIAL: null,
          PRECOESPECIAL2: null,
          PRECOESPECIAL3: null,
          PRECO: '15.000',
          VALORTOTAL: '15.000',
          QUANTIDADEEMBALAGEM: null,
          PRECOEMBALAGEM: null,
          PRECOMOEDAPRINCIPAL: '15.000',
          PERCENTUALDESCONTO: null,
          VALORDESCONTO: null,
          VALORDESCONTOUNITARIO: null,
          TIPOPRECO: 0,
          NUMERO: 58581,
          SEQ: null,
          ITEM: '88356',
          SERIE: '01',
          MEDIDA: null,
          LIBERADO: 'S',
          TIPO: 'Orçamento',
          MARCADO: null,
          CUSTOMEDIO: null,
          OUTROSCUSTOS: '0.00',
          CODIGOUSUARIO: null,
          FILIAL: 1,
          FILIALENTREGA: null,
          OBRA: null,
          FASE: null,
          DATA: '2023-05-25 00:00:00',
          CONVERSOR: '1.0000',
          ITEMESTOQUE: '88356',
          COMPLEMENTO: null,
          COMPLEMENTODESCRICAO: 'MIM ONDA CONJ BODY 221013 CALCA 03',
          EMPRESACODIGO: 4435,
          FILIALNF: 1,
          DEPARTAMENTO: null,
          OS: 'N',
          SOMOUEMTOTAL: 'S',
          VENDEDOR: null,
          LETRADESCONTO: null,
          EMOFERTA: null,
          CONTROLEENTREGA: null,
          LOTE: null,
          PDVQUANTIDADEREF: null,
          DESAGIO: null,
          SEQUENCIALLCTOVENDEDORES: null,
          DATAINCLUSAO: null,
          SEQUENCIALLISTA: '0.0000',
          PERCACRESCFINANCEIRO: null,
          VALORACRESCFINANCEIRO: null,
          VALOROUTROSACRESCIMOS: null,
          GRADELINHA: null,
          GRADECOLUNA: null,
          QUANTIDADEDEVOLUCAO: null,
        },
      ],
    },
    xbilling: {
      pix: {
        calendario: {
          criacao: '2023-05-25T16:53:46Z',
          expiracao: 3600,
          dataexpiracao: '2023-05-25T19:58:42Z',
        },
        chave: 13,
        status: 'ATIVA',
        txid: 1968,
        valor: {
          original: 288.35,
          moedaOrigem: 'USD',
        },
        textoImagemQRcode:
          '00020101021226830014BR.GOV.BCB.PIX2561api-h.rendimento.com.br/q/v2/b452d1fc234547b7b90bb032da7d6ba75204000053039865802BR5923AGILLITAS SOLUCOES DE P6009SAO PAULO61080115300062070503***6304C55A',
        psp: 'agillitas',
      },
    },
  }
}

let timmer = null // manter fora da funçaõ para não ser recriado a cada chamada

export const debounceIt = (fn, delay) => {
  return function (...args) {
    clearTimeout(timmer)
    timmer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

export const generateWhatsappLink = (value) => {
  if (!value) return null
  else return `https://wa.me/${value}`
}
