export const billingModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    isLoading: true,
    paymentData: null,
    paymentMethod: null,
    paymentInstallments: [],
    installmentChoice: null,
  },
  mutations: {
    SET_IS_LOADING(state, value) {
      state.isLoading = value
    },
    SET_OPTION(state, value) {
      state.option = value
    },
    SET_INSTALLMENT_CHOICE(state, value) {
      state.installmentChoice = value
    },
    SET_PAYMENT_DATA(state, value) {
      state.paymentData = value
    },
    SET_PAYMENT_METHOD(state, value) {
      state.paymentMethod = value
    },
    SET_PAYMENT_INSTALLMENTS(state, value) {
      state.paymentInstallments = Object.assign([], value?.parcelas)
    },
  },
  actions: {
    setIsLoading({ commit }, value) {
      commit('SET_IS_LOADING', value)
    },
    setOption({ commit }, value) {
      commit('SET_OPTION', value)
    },
    setInstallmentChoice({ commit }, value) {
      commit('SET_INSTALLMENT_CHOICE', value)
    },
    setPaymentData({ commit }, value) {
      commit('SET_PAYMENT_DATA', value)
    },
    setPaymentMethod({ commit }, value) {
      commit('SET_PAYMENT_METHOD', value)
    },
    setPaymentInstallments({ commit }, value) {
      commit('SET_PAYMENT_INSTALLMENTS', value)
    },
    clearPaymentData({ commit }) {
      commit('SET_PAYMENT_DATA', null)
      commit('SET_PAYMENT_METHOD', null)
      commit('SET_PAYMENT_INSTALLMENTS', null)
      commit('SET_INSTALLMENT_CHOICE', null)
    },
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading
    },
    option: (state) => {
      return state.option
    },
    paymentData: (state) => {
      return state.paymentData
    },
    paymentMethod: (state) => {
      return state.paymentMethod
    },
    paymentInstallments: (state) => {
      return state.paymentInstallments
    },
    installmentChoice: (state) => {
      return state.installmentChoice
    },
  },
}
