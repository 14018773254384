import Service from '@/services/Service'

class AuthService extends Service {
  constructor() {
    super()
    this._loginURL = '/auth'
    this._loginCompanyURL = 'mbcommerce/auth-company'
    this._authUserURL = this._loginURL + '/me'
    this._authCompanyURL = this._loginCompanyURL + '/me'
    this._logoutUrl = this._loginURL + '/logout'
    this._logoutCompanyUrl = this._loginCompanyURL + '/logout'
    this._authorizationCompanyURL = this._loginCompanyURL + '/authorization'
    this._updatePasswordCompanyURL = this._loginCompanyURL + '/change-password'
  }

  // VENDEDORES AUTH

  async loginUser(userData) {
    const userData64 = {
      NOME: btoa(btoa(userData.NOME) + 'gw'),
      password: btoa(btoa(userData.password) + 'gw'),
    }

    const response = await this._http.post(
      this._loginURL + '/login',
      userData64
    )
    return response
  }

  async getAuthUser(token) {
    return await this._http.post(this._authUserURL, { token: token })
  }

  // EMPRESA AUTH

  async loginCompany(payload) {
    const { data } = await this._http.post(this._loginCompanyURL, payload)
    return data
  }

  async getAuthCompany(token) {
    return await this._http.post(this._authCompanyURL, {
      token: token,
    })
  }
  async logoutCompany(token) {
    return await this._http.post(this._logoutCompanyUrl, {
      token: token,
    })
  }

  async resetPasswordCompany(payload) {
    return await this._http.post(this._authorizationCompanyURL, payload)
  }

  async updatePasswordCompany(payload) {
    return await this._http.post(this._updatePasswordCompanyURL, payload)
  }
}

export default new AuthService()
