import Service from '../Service'

class CategoryIntegrationService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/categories-integration'
  }

  async getCategoriesIntegration(query) {
    let requestUrl = this.createRequestUrl(query)

    return await this.getAPI(requestUrl)
  }

  async getCategoryIntegrationById(id, query) {
    const resource = `/${this.resource}/${id}`
    let requestUrl = this.createRequestUrl(query, resource)
    return await this.getAPI(requestUrl)
  }

  async deleteArrayCategoriesIntegration(ids, query) {
    let requestUrl = `/${this.resource}/destroyArrayofCategories`
    return await this._http.delete(requestUrl, {
      data: { itens: ids, integracao: query?.integracao },
    })
  }

  async deleteCategoryIntegration(id, query) {
    const resource = `/${this.resource}/${id}`
    let requestUrl = this.createRequestUrl(query, resource)
    return await this._http.delete(requestUrl)
  }

  async insertCategoriesIntegration(payload) {
    return await this.insert(payload)
  }
}

export default new CategoryIntegrationService()
