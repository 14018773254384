import Service from '@/services/Service'

class AgillitasService extends Service {
  constructor() {
    super('AGILLITAS')
    this.resource = `/mbcommerce/billing/exchange-rate`
  }

  async getExchangeRate() {
    return await this.getAPI(this.resource)
  }
}

export default new AgillitasService()
