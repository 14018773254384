import Service from '../Service'

class UserService extends Service {
  constructor() {
    super()
    this.resource = 'users'
  }

  async getUsers(query) {
    let requestUrl = this.createRequestUrl(query)

    return await this.getAPI(requestUrl)
  }

  async getUser(id) {
    let requestUrl = `${this.resource}/${encodeURIComponent(id)}`
    return await this.getAPI(requestUrl)
  }
}

export default new UserService()
