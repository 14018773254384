import Service from '../Service'

class StateService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/states'
  }

  async getStates(query) {
    let requestUrl = this.createRequestUrl(query)
    return await this.getAPI(requestUrl)
  }
}

export default new StateService()
