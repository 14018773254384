import Service from '../Service'
class ManufacturersService extends Service {
  constructor() {
    super()
    this.resource = 'manufacturer'
  }

  async getManufacturers(query = null) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }
}

export default new ManufacturersService()
