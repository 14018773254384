import Service from '../Service'

class CategoryService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/categories'
  }

  async getCategories(query) {
    let requestUrl = this.createRequestUrl(query)

    return await this.getAPI(requestUrl)
  }

  async getCategory(query,id) {
    let requestUrl = `/${this.resource}/${id}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }

  async getSelectedCategorys(payload, query) {
    let requestUrl = this.createRequestUrl(query , 'getSelected')
    requestUrl = `${this.resource}/${requestUrl}`
    const { data } = await this._http.post(requestUrl, payload)
    return data
  }

}

export default new CategoryService()
