import Service from '@/services/Service'

class RegionService extends Service {
  constructor() {
    super('REGION')
    this.resource = 'mbcommerce/region'
    this.freight = 'frenet'
  }

  async getRegionTax(query) {
    let requestUrl = `${this.resource}/price`
    requestUrl = this.createRequestUrl(query, requestUrl)
    let response = await this.getAPI(requestUrl)
    return response
  }

  async getDeliveryFee(query) {
    let requestUrl = `${this.resource}/delivery-fee`
    requestUrl = this.createRequestUrl(query, requestUrl)
    let response = await this.getAPI(requestUrl)
    return response
  }

  // freight
  async getFrenetQuotes(query) {
    let requestUrl = `${this.freight}/quote`
    const { data } = await this._http.post(requestUrl, query)
    return data
  }

  async getFrenetInfo(query) {
    let requestUrl = `${this.freight}/info`
    requestUrl = this.createRequestUrl(query, requestUrl)
    let response = await this.getAPI(requestUrl)
    return response
  }
}

export default new RegionService()
