import Service from '@/services/Service'

class ProductService extends Service {
  constructor() {
    super('PRODUCT')
    this.resource = 'mbcommerce/products'
  }

  async getProducts(query = null) {
    let requestUrl = this.createRequestUrl(query)
    let response = await this.getAPI(requestUrl)
    return response
  }

  async getProduct(sequencial) {
    let requestUrl = `${this.resource}/${encodeURIComponent(sequencial)}`
    return await this.getAPI(requestUrl)
  }

  async getItems(items) {
    try {
      let requestUrl = `${this.resource}/getItems`
      return await this._http.post(requestUrl, items)
    } catch (e) {
      return this.handlingError(e)
    }
  }

  async getItemsStock(items) {
    try {
      let requestUrl = `${this.resource}/getItemStock`
      return await this._http.post(requestUrl, items)
    } catch (e) {
      return this.handlingError(e)
    }
  }

  async getSuggestions(query) {
    let requestUrl = `${this.resource}/suggestions`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }
}

export default new ProductService()
