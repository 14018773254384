import Service from '@/services/Service'

class AccountService extends Service {
  constructor() {
    super()
    this.resource = 'accounts'
  }

  async vefirifyIfExist(payload) {
    let requestUrl = `/${this.resource}/verifyIfExist/${payload}`
    return await this.getAPI(requestUrl)
  }

  async getAccouts(query) {
    let requestUrl = this.createRequestUrl(query)
    return await this.getAPI(requestUrl)
  }
}

export default new AccountService()
