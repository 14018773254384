import service from '@/services/freight/FreightService'

export const freightModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    freight: null,
    availableCarriers: [],
    errorFreight: null,
    isFreightSearched: false,
  },
  mutations: {
    /**
     * Popula state com retorno da consulta
     */
    SET_FREIGHT(state, payload) {
      if (payload) {
        state.freight = {
          ServiceCode: payload.ServiceCode,
          ServiceDescription: payload.ServiceDescription,
          Carrier: payload.Carrier,
          CarrierCode: payload.CarrierCode,
          ShippingPrice: payload.ShippingPrice,
          DeliveryTime: payload.DeliveryTime,
          OriginalDeliveryTime: payload.OriginalDeliveryTime,
          OriginalShippingPrice: payload.OriginalShippingPrice,
          ResponseTime: payload.ResponseTime,
          AllowBuyLabel: payload.AllowBuyLabel,
        }
      } else state.freight = null
    },
    SET_CARRIERS(state, payload) {
      if (payload instanceof Array) state.availableCarriers = payload
    },
    SET_IS_FREIGHT_SEARCH(state, payload) {
      state.isFreightSearched = payload
    },
    ADD_CARRIER(state, payload) {
      let carr = state.availableCarriers?.filter(
        (carrier) => carrier.ServiceCode != payload.ServiceCode
      )
      carr.push(payload)
      state.availableCarriers = carr
    },
  },
  actions: {
    /**
     * Consultar fretes disponíveis
     */
    async getFreightQuotes({ commit }, query) {
      const response = await service.getFreightQuotesService(query)
      await commit('SET_CARRIERS', response)
    },
    /**
     * Setar frete escolhido pelo usuário
     */
    async setFreightStore({ commit }, query) {
      await commit('SET_FREIGHT', query)
    },
    setCarriers({ commit }, payload) {
      commit('SET_CARRIERS', payload)
    },
    setIsFreightSearched({ commit }, payload) {
      commit('SET_IS_FREIGHT_SEARCH', payload)
    },
    addCarrier({ commit }, query) {
      commit('ADD_CARRIER', query)
    },
  },

  getters: {
    /**
     * Retonar o state do frete escolhido pelo usuário;
     */
    getFreight: (state) => {
      return state.freight
    },
    getIsFreightSearched: (state) => {
      return state.isFreightSearched
    },
    getAvailableCarriers: (state) => {
      return state.availableCarriers
    },
  },
}
