import service from '@/services/company-list/CompanyListService'

let idCompanyList = localStorage.getItem('idCompanyList') || null

export const companyListModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    idCompanyList,
    companyList: null,
    companyListList: [],
    products: [],
    filteredProductList: [],
    loadingItems: false,
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
    },
    headerList: [],
    filter: {
      type: 0,
      fromPrice: 0,
      toPrice: 0,
    },
    prices: [
      'Todos',
      'Até R$25',
      'R$25 a R$100',
      'R$100 a R$500',
      'Acima de R$500',
    ],
  },
  mutations: {
    SETCOMPANYLISTSLIST(state, payload) {
      state.companyListsList = payload.data
      state.pagination.current_page = parseInt(payload.current_page)
      state.pagination.last_page = parseInt(payload.last_page)
      state.pagination.per_page = parseInt(payload.per_page)
      state.headerList = []
    },
    SET_COMPANY_LIST(state, payload) {
      state.companyList = payload
    },
    SET_LOADING(state) {
      state.loadingItems = !state.loadingItems
    },
    SET_ID_COMPANY_LIST(state, payload) {
      state.idCompanyList = payload
      if (payload && payload > 0) localStorage.setItem('idCompanyList', payload)
      else localStorage.removeItem('idCompanyList')
    },
  },

  actions: {
    async getCompanyListsList({ commit }, payload) {
      try {
        commit('SET_LOADING')
        const response = await service.getCompanyLists(payload)
        await commit('SETCOMPANYLISTSLIST', response)
      } finally {
        commit('SET_LOADING')
      }
    },

    async getCompanyListList({ state, rootState, commit, dispatch }, payload) {
      const response = await service.getCompanyList(payload)
      let categorias = []
      let items = []
      if (response.produtos) {
        items = response.produtos.filter(
          (product) =>
            product.SALDOITEM > 0 &&
            (product.QUANTIDADE ?? 0) > product.QUANTIDADEPROC
        )
        categorias = response.categorias

        const nwPayload = { items, categorias }

        await dispatch('productModule/setProductList', nwPayload, {
          root: true,
        })
        await commit('productModule/SET_PRICES_LIST', response.precos, {
          root: true,
        })
      }

      let list = []
      items.forEach((product) => {
        const idx = list.findIndex((i) => i.MARCA == product.itens.MARCA)
        if (idx < 0) list.push({ QTDITENS: 1, MARCA: product.itens.MARCA })
        else
          list[idx] = {
            QTDITENS: (list[idx].QTDITENS += 1),
            MARCA: product.itens.MARCA,
          }
      })
      list = list.sort()
      await commit('SET_COMPANY_LIST', response)
      await commit('SET_ID_COMPANY_LIST', payload)
      commit('productModule/SET_SUBCATEGORIES_LIST', list, {
        root: true,
      })

      if (
        !rootState.systemModule.system ||
        (rootState.systemModule.system &&
          rootState.systemModule.system.CODIGO != state.companyList.FILIAL)
      )
        await dispatch(
          'systemModule/getSystem',
          state.companyList ? state.companyList.FILIAL : 1,
          { root: true }
        )

      await dispatch('productModule/retrieveCart', null, {
        root: true,
      })
    },

    async setIdCompanyList({ commit, dispatch }, payload) {
      await commit('SET_ID_COMPANY_LIST', payload)
      await dispatch('getCompanyListList', payload)
    },

    async checkItemsList({ state, dispatch }) {
      const { idCompanyList } = state

      if (idCompanyList && idCompanyList > 0)
        await dispatch('getCompanyListList', idCompanyList)
    },

    async retrieveCompanyList({ dispatch }, payload) {
      await dispatch('setIdCompanyList', payload)
      await dispatch('productModule/retrieveCart', null, { root: true })
    },

    async clearCompanyList({ state, commit, dispatch }) {
      await commit('SET_ID_COMPANY_LIST', null)
      await commit('SET_COMPANY_LIST', null)
      await dispatch('productModule/clearCart', null, {
        root: true,
      })
      await dispatch('productModule/clearWishList', null, {
        root: true,
      })
      await dispatch('productModule/setProductList', null, { root: true })
    },
  },
  getters: {
    filteredProductsList: (state, getters) => {
      return state.filteredProductList ?? getters.products
    },
    companyList: (state) => {
      return state.companyList
        ? {
            sequencial: state.companyList.SEQUENCIAL,
            id: state.companyList.CODIGO,
            title: state.companyList.NOME,
            seller: state.companyList.vendedor.NOMECOMPLETO,
            sellerName: state.companyList.VENDEDOR,
            sellerId: state.companyList.vendedor.CODIGO,
            obs: state.companyList.OBSERVACAO,
            date: state.companyList.DATA,
            dateInsert: state.companyList.DATAINCLUSAO,
            client: state.companyList.empresa,
          }
        : null
    },

    products: (state) => {
      return state.products
    },
  },
}
