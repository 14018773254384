import service from '@/services/order/OrderService'

export const adminOrderModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    orders: null,
    orderDetail: null,
    loadingItems: false,
    pagination: {
      currentPage: 1,
      perPage: 1,
      lastPage: 1,
      total: 1,
    },
  },
  mutations: {
    SET_ORDERS(state, response) {
      if (response === null) state.orders = null
      else {
        const dados = response.data ?? response
        state.orders = dados
      }
    },

    SET_ORDER(state, response) {
      if (response === null) state.orderDetail = null
      else {
        const dados = response.data ?? response
        state.orderDetail = dados
      }
    },

    SET_AS_EXPORTED(state, payload) {
      const dados = payload.response.data ?? payload.response
      if (state.orders === null) {
        state.orderDetail.freteEcm = [
          { codigoRemessa: dados.items[0].shipmentId },
        ]
      } else {
        const index = state.orders.findIndex(
          (item) =>
            item.numero === payload.orderId && item.filial === payload.filial
        )
        const codigoRemessa = dados.items[0].shipmentId
        if (codigoRemessa) {
          if (!state.orders[index].freteEcm[0]) {
            // para quando não houver relacionamento com a tabela orcamentos_frete_ecm, adiciona
            state.orders[index].freteEcm = [{ codigoRemessa: codigoRemessa }]
          } else state.orders[index].freteEcm[0].codigoRemessa = codigoRemessa
        } else throw 'Houve algum erro no envio do pedido para o Frenet'
      }
    },

    SET_PAGINATION(state, data) {
      if (data && data.current_page) {
        state.pagination.currentPage = data.current_page
        state.pagination.lastPage = data.last_page
        state.pagination.total = data.total
      }
      state.pagination.perPage = parseInt(data.per_page)
    },
  },
  actions: {
    async getOrder({ commit }, payload) {
      const response = await service.getAdminOrder(payload)
      commit('SET_ORDER', response)
      commit('SET_ORDERS', null)
      return response
    },

    async getOrders({ commit }, payload) {
      try {
        const response = await service.getAdminOrders(payload)

        commit('SET_ORDERS', response)
        commit('SET_ORDER', null)

        commit('SET_PAGINATION', response.meta)
      } catch (error) {
        console.error('getOrders: ', error)
      } finally {
      }
    },

    async sendOrderToFreightHub({ commit }, payload) {
      try {
        const response = await service.sendOrderToFreightHub(payload)

        payload.response = response

        commit('SET_AS_EXPORTED', payload)

        return response
      } catch (error) {
        throw new Error(error)
      }
    },
  },
  getters: {
    orders: (state) => {
      return state.orders
    },

    orderDetail: (state) => {
      return state.orderDetail
    },
  },
}
