export default [
  {
    title: 'Brasil',
    siglaPais: 'BRA',
    flagSrc: '/images/br.png',
    frmPY: false,
  },
  {
    title: 'Paraguai',
    siglaPais: 'PRY',
    flagSrc: '/images/py.png',
    frmPY: true,
  },
  {
    title: 'Argentina',
    siglaPais: 'ARG',
    flagSrc: '/images/ar.png',
    frmPY: false,
  },
]
