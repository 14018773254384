import Service from '../Service'

class IntegrationService extends Service {
  constructor() {
    super()
    this.resource = '/mbcommerce/integrations'
  }

  async getIntegrations(query = null) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }

  async checkFreights(){
    let requestUrl = `${this.resource}/checkFreights`
    return await this.getAPI(requestUrl)
  }

  async saveIntegration(payload) {
    return await this.insert(payload)
  }

  async updateIntegration(payload) {
    return await this.update(payload, payload.ID)
  }

  async patchAreaAtuacaoFrete(payload) {
    let requestUrl = `${this.resource}/area-atuacao-frete`
    return await this.patch(requestUrl, payload, payload.id)
  }

  async getColors(payload, query) {
    let requestUrl = `${this.resource}/colors/${payload.integration}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }

  async getAreaAtuacaoFrete(payload, query) {
    let requestUrl = `${this.resource}/area-atuacao-frete`
    return await this.getAPI(requestUrl)
  }

  /** TODO Verificar */
  async getIntegration(payload) {
    let requestUrl = `${this.resource}/${payload}`
    return await this.getAPI(requestUrl)
  }

  async verifyIntegration(payload) {
    let requestUrl = `${this.resource}/verify/${payload}`
    return await this.getAPI(requestUrl)
  }
}

export default new IntegrationService()
