<template>
  <component :is="componentName" />
</template>

<script>
export default {
  components: {
    Admin: () => import('./views/app/Admin'),
    Ecommerce: () => import('./views/app/Ecommerce'),
  },

  computed: {
    componentName() {
      return this.$store.state.adminModule.adminContext // Ecommerce ou Admin
    },
    apiKeyDirections() {
      return process.env.VUE_APP_API_DIRECTIONS
    },
    urlGoogleApi() {
      return `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyDirections}&libraries=places&callback=Function.prototype`
    },
  },
  methods: {
    addScriptGoogleApi() {
      if (this.apiKeyDirections) {
        if (!this.verifyHasScriptGoogleApi()) {
          const script = document.createElement('script')
          script.async = true
          script.src = this.urlGoogleApi
          document.head.appendChild(script)

          script.onerror = () => {
            console.error('Falha ao iniciar serviço Google API')
          }
        }
      }
    },

    verifyHasScriptGoogleApi() {
      let result = false
      const scripts = document.head.getElementsByTagName('script')
      if (scripts?.length > 0) {
        for (const script in scripts) {
          if (script.src && script.src === this.urlGoogleApi) {
            result = true
            break
          }
        }
      }
      return result
    },

    getBackgroundColor() {
      if (this.$vuetify.theme.isDark) {
        return (
          'background-color:' + this.$vuetify.theme.themes.dark.bodyBackground
        )
      }
      return (
        'background-color:' + this.$vuetify.theme.themes.light.bodyBackground
      )
    },
  },
  async updatePage(e) {
    var confirmationMessage = 'o'
    let routesBlockedReload = ['checkout']
    let routeName = this.$route.name
    let found = null
    found = routesBlockedReload.find((ele) => {
      if (routeName) return routeName.indexOf(ele) !== -1
      else return null
    })
    if ((found && e) || this.tokenInvalid) {
      e.returnValue = confirmationMessage
      // Gecko, Trident, Chrome 34+
      return confirmationMessage // Gecko, WebKit, Chrome <34
    } else return null
  },

  mounted() {
    this.addScriptGoogleApi()
    window.addEventListener('beforeunload', this.updatePage, false)
    // this.$vuetify.theme.isDark = true;
  },
}
</script>
