import service from '@/services/item-integration/ItemIntegrationService'

const selectedItensInIntegration = localStorage.getItem(
  'selectedItensInIntegration'
)
let selectedItens = selectedItensInIntegration
  ? JSON.parse(selectedItensInIntegration)
  : []

export const itemIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    itemsIntegrationList: [],
    noMoreData: false,
    selectedItens,
  },
  mutations: {
    ADD_SELECTED_ITENS(state, payload) {
      const mappedPayload = payload.map((item) => {
        const obj = {
          CODIGO: item?.CODIGO,
          DESCRICAO: item?.DESCRICAO,
        }
        const exist = state.selectedItens.find((i) => i.CODIGO == item?.CODIGO)
        return exist || obj
      })

      state.selectedItens = mappedPayload
      localStorage.setItem(
        'selectedItensInIntegration',
        JSON.stringify(state.selectedItens)
      )
    },
    REMOVE_SELECTED_ITENS(state, payload) {
      state.selectedItens = state.selectedItens.filter(
        (item) => item.CODIGO != payload
      )
      localStorage.setItem(
        'selectedItensInIntegration',
        JSON.stringify(state.selectedItens)
      )
    },
    CLEAR_SELECTED_ITENS(state) {
      state.selectedItens = []
      localStorage.setItem(
        'selectedItensInIntegration',
        JSON.stringify(state.selectedItens)
      )
    },

    SET_ITEMS_INTEGRATION_LIST(state, payload) {
      if (payload.forward) {
        const newItem = payload.data
        const uniqueItems = newItem.filter((newItemObject) => {
          return !state.itemsIntegrationList.some((existingItem) => {
            return existingItem.CODIGO === newItemObject.CODIGO
          })
        })
        state.itemsIntegrationList.push(...uniqueItems)
      } else {
        state.itemsIntegrationList = payload.data
      }
    },
    SET_OBS_ITEM(state, payload) {
      const idx = state.itemsIntegrationList.findIndex(
        (item) => item.CODIGO == payload.CODIGO
      )
      state.itemsIntegrationList[idx].OBS = payload.OBS
    },
    REMOVE_ITEM_FROM_LIST(state, payload) {
      const idx = state.itemsIntegrationList.findIndex(
        (item) => item.CODIGO == payload
      )
      state.itemsIntegrationList.splice(idx, 1)
    },

    REFRESH_ITEMS_AFTER_CHANGES(state, payload) {
      state.itemsIntegrationList = payload
    },

    SET_NO_MORE_DATA(state, payload) {
      state.noMoreData = payload
    },
  },
  actions: {
    setObsItem({ commit }, payload) {
      try {
        commit('SET_OBS_ITEM', payload)
      } catch (error) {
        console.error(error)
      }
    },

    deleteFromList({ commit }, payload) {
      try {
        commit('REMOVE_SELECTED_ITENS', payload)
      } catch (error) {
        console.error(error)
      }
    },

    setSelectedItens({ commit }, payload) {
      commit('ADD_SELECTED_ITENS', payload)
    },

    clearSelectedItens({ commit }) {
      commit('CLEAR_SELECTED_ITENS')
    },

    async getItemsIntegrationAPI({ commit, rootState, state }, q) {
      try {
        let query = { ...q }
        query.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
        query.admin = true
        query.hibrido = query.hibrido ?? ''
        query.page = query.page ?? 1
        query.per_page = query.per_page ?? 15
        let response = await service.getItemsIntegration(query)
        commit('SET_NO_MORE_DATA', response.last_page == query.page)
        if (query.page > 1) response.forward = true

        commit('SET_ITEMS_INTEGRATION_LIST', response)
      } catch (e) {
        throw e
      }
    },

    resetNoMoreData({ commit }) {
      commit('SET_NO_MORE_DATA', false)
    },

    async getItemIntegrationAPI({}, CODIGO) {
      let data = await service.getItemIntegration(CODIGO)
      return data
    },

    async insertItemIntegration({ rootState }, payload = null) {
      try {
        let listPayload = !payload
          ? rootState.itemModule.listItemsForIntegration
          : payload

        if (!payload) {
          const imageList = rootState.itemModule.itemsImageListForIntegration
          if (imageList.length > 0)
            listPayload = listPayload.map((item) => {
              item.IMAGENS =
                imageList
                  .find((image) => image.CODIGO == item.CODIGO)
                  ?.IMAGENS.map((i) => {
                    if (i && i.includes('data:image')) {
                      return i.split(',')[1]
                    } else return i
                  }) || []

              return item
            })
        }

        const integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
        const { data } = await service.insertItemIntegration(
          { itens: listPayload },
          integracao
        )
        return data
      } catch (error) {
        return error
      }
    },

    async transformSelectedItens({ dispatch, state }, payload) {
      const newPayload = state.selectedItens.map((item) => {
        let obj = { ...payload }
        obj.CODIGO = item.CODIGO
        return obj
      })
      return await dispatch('insertItemIntegration', newPayload)
    },

    async deleteItemIntegration({ commit, rootState }, CODIGO) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      }
      const arrayOfCodes = Array.isArray(CODIGO)
      const status = arrayOfCodes
        ? await service.destroyArrayofItens(CODIGO, query.integracao)
        : await service.deleteItemIntegration(CODIGO, query)
      if (!arrayOfCodes) commit('REMOVE_ITEM_FROM_LIST', CODIGO)
      else {
        for (let i = 0; i < CODIGO.length; i++) {
          commit('REMOVE_ITEM_FROM_LIST', CODIGO[i])
        }
      }
      return status
    },
  },
  getters: {
    itemsIntegrationList: (state) => state.itemsIntegrationList,
    noMoreData: (state) => state.noMoreData,
    selectedItensLength: (state) => state.selectedItens.length,
    selectedItensInfo: (state) =>
      state.selectedItens.map((e) => {
        return { id: e.CODIGO, name: e.DESCRICAO }
      }),
  },
}
