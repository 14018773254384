import i18n from '@/i18n'
const selectedLang = localStorage.getItem('selectedLang')
  ? localStorage.getItem('selectedLang')
  : null
export const headerModule = {
  namespaced: true,
  state: {
    lang: selectedLang,
  },
  mutations: {
    SET_LANG(state, payload) {
      state.lang = payload
      localStorage.setItem('selectedLang', payload)
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit('SET_LANG', payload)
    },
  },
  getters: {
    getLang: (state) => {
      return state.lang
    },
    itemsMenu: (state) => {
      return [
        {
          id: null,
          title: i18n.t('ecommerce.topnav.logInOrRegister'),
          link: '/company',
          route: { name: 'dashboard' },
          icon: '',
          size: '36px',
        },
        {
          id: 'wishListQty',
          title: i18n.t('ecommerce.topnav.favorites'),
          link: '/wish-list',
          route: { name: 'wishList' },
          icon: '',
          size: '34px',
        },
        {
          id: 'contact',
          title: i18n.t('ecommerce.topnav.contactUs'),
          link: '/contact',
          route: { name: 'contact' },
          icon: '',
          size: '38px',
        },
        {
          id: 'cartQty',
          title: i18n.t('ecommerce.topnav.cart'),
          link: '/cart',
          route: { name: 'cart' },
          icon: '',
          size: '38px',
        },
      ]
    },
  },
}
