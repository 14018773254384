import service from '@/services/region/RegionService'

let regionTax = {
  code: 0,
  name: '',
  state: '',
  route: '',
  neighbourhood: '',
  price: 0,
}

export const regionModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    region: null,
    regionTax: null,
    deliveryFee: null,
    addressLocation: null,
    placesGoogle: null,
    alertCepIcon: {
      icon: '',
      message: '',
    },
  },
  mutations: {
    SET_ALERTCEP(state, payload) {
      state.alertCepIcon = Object.assign({}, payload)
    },
    SET_REGION(state, payload) {
      state.region = Object.assign({}, payload)
    },
    SET_ADDRESSLOCATION(state, payload) {
      state.addressLocation = payload
    },
    SET_PLACESGOOGLE(state, payload) {
      state.placesGoogle = payload
    },

    SET_DELIVERY_FEE(state, payload) {
      state.regionTax = payload
    },
  },
  actions: {
    /**
     * Consultar rota de região para obter valor do frete
     */
    async getRegionTax({ commit }, query) {
      const response = await service.getRegionTax(query)
      await commit('SET_REGION_TAX', response)
    },
    /**
     * Consultar distanciasfrete para obter valor do frete
     */
    async getDeliveryFee({ commit }, query) {
      query.moeda = this.getters['systemModule/getMainCurrency']
      const response = await service.getDeliveryFee(query)
      await commit('SET_DELIVERY_FEE', response)
    },

    /**
     * Cria objeto vindo da consultas de cep
     */

    async setAlertCep({ commit }, payload) {
      await commit('SET_ALERTCEP', payload)
    },
    async setRegion({ commit }, payload) {
      await commit('SET_REGION', payload)
    },
    async setAddressLocation({ commit }, payload) {
      await commit('SET_ADDRESSLOCATION', payload)
    },
    async setPlacesGoogle({ commit }, payload) {
      await commit('SET_PLACESGOOGLE', payload)
    },
    async setRegionTax({ commit }, payload) {
      await commit('SET_DELIVERY_FEE', payload)
    },
  },
  getters: {
    regionTax: (state) => {
      return state.regionTax
    },
    region: (state) => {
      return state.region
    },
    deliveryFee(state, getters, rootState, rootGetters) {
      const getFreight = rootGetters['freightModule/getFreight']
      const value =
        getFreight && getFreight?.ShippingPrice
          ? parseFloat(getFreight?.ShippingPrice)
          : 0

      return parseFloat(value)
    },
  },
}
