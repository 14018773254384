

const storageAdminProperty = 'adminFilial'
const storageAdminValue = localStorage.getItem(storageAdminProperty)

const storageAdminIntegrationProperty = 'adminIntegration'
const storageAdminIntegrationValue = localStorage.getItem(
  storageAdminIntegrationProperty
)

let selectedFilial = storageAdminValue ? JSON.parse(storageAdminValue) : 1
let selectedIntegration = storageAdminIntegrationValue
  ? JSON.parse(storageAdminIntegrationValue)
  : null

let selectedThemeAdmin = localStorage.getItem('selectedThemeAdmin')
  ? localStorage.getItem('selectedThemeAdmin')
  : 'light'

export const adminModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    adminContext: null, // 'Ecommerce', 'Admin'
    hasBaseEcommerceIntegration: false,
    selectedIntegration,
    selectedFilial,
    selectedThemeAdmin,
  },
  mutations: {
    updateAdminContext(state, payload) {
      const validsContexts = ['Ecommerce', 'Admin']
      if (validsContexts.findIndex((e) => e == payload) > -1)
        state.adminContext = payload
      else state.adminContext = 'Ecommerce'
    },
    updateFilial(state, payload) {
      state.selectedFilial = payload

      localStorage.setItem(storageAdminProperty, state.selectedFilial)
    },
    updateIntegration(state, payload) {
      payload = {
        ID: payload.ID,
        NOME_EMPRESA: payload.NOME_EMPRESA,
      }
      state.selectedIntegration = payload
      localStorage.setItem(
        storageAdminIntegrationProperty,
        JSON.stringify(state.selectedIntegration)
      )
    },

    CLEAR_SELECTED_INTEGRATION(state) {
      state.selectedIntegration = null
      localStorage.removeItem(storageAdminIntegrationProperty)
    },

    SET_HAS_BASE_ECOMMERCE_INTEGRATION(state, payload) {
      state.hasBaseEcommerceIntegration = payload
    },

    SET_SELECTED_THEME_ADMIN(state, payload) {
      state.selectedThemeAdmin = payload
    },
  },
  actions: {
    setAdminContext({ commit }, payload) {
      commit('updateAdminContext', payload)
    },

    setSelectedThemeAdmin({ commit }, payload) {
      commit('SET_SELECTED_THEME_ADMIN', payload)
      localStorage.setItem('selectedThemeAdmin', payload)
    },

    setHasBaseEcommerceIntegration({ commit }, payload) {
      commit('SET_HAS_BASE_ECOMMERCE_INTEGRATION', payload)
    },

    setFilial({ commit }, payload) {
      commit('updateFilial', payload)
    },
    setIntegration({ commit }, payload) {
      commit('updateIntegration', payload)
    },
    clearSelectedIntegration({ commit }, payload) {
      commit('CLEAR_SELECTED_INTEGRATION')
    },
  },
  getters: {
    selectedThemeAdmin: (state) => state.selectedThemeAdmin,
  },
}
