import service from '@/services/contact-us/ContactService'

export const contactModule = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state : {
    },
    mutations: {
    },
    actions: {
        async sendContactEmail(context , payload){
           return await service.insert(payload)
        }
    },
    getters: {

    }
}