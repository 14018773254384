import Service from '@/services/Service'

class RegionService extends Service {
  constructor() {
    super('')
    this.resource = 'frenet'
  }

  async getFreightQuotesService(query) {
    let requestUrl = `${this.resource}/quote`
    const { data } = await this._http.post(requestUrl, query)
    return this.cleanItemsWithError(data.ShippingSevicesArray)
  }

  async getFreightInfoService(query) {
    let requestUrl = `${this.resource}/info`
    requestUrl = this.createRequestUrl(query, requestUrl)
    let response = await this.getAPI(requestUrl)
    return response
  }

  cleanItemsWithError(carriers) {
    return carriers?.filter((e) => e.Error === false)
  }
}

export default new RegionService()
