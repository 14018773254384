import Service from '@/services/Service'

class ShippingService extends Service {
  constructor() {
    super()
    this.resource = 'region'
  }

  async getTaxas(query) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }

  async getById(payload) {
    let requestUrl = `/${this.resource}/${payload}`
    return await this.getAPI(requestUrl)
  }
}

export default new ShippingService()
