import Service from '@/services/Service'

class SystemService extends Service {
  constructor() {
    super('SYSTEM')
    this.resource = 'mbcommerce/system'
  }

  async getSystem(filial, query = null) {
    let requestUrl = `${this.resource}/${encodeURIComponent(filial)}`
    if(query) requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }

  async getSystems(query = null) {
    if (!query) query = {}
    let requestUrl = this.createRequestUrl(query)
    return await this.getAPI(requestUrl)
  }
}

export default new SystemService()
