import Service from '../Service'
class ManufacturersIntegrationService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/manufacturers'
  }

  async getManufacturers(query = null) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }

  async deleteManufacturers(query, id) {
    let requestUrl = `/${this.resource}/${id}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this._http.delete(requestUrl)
  }

  async deleteArrayManufacturers(query, payload) {
    let requestUrl = `/${this.resource}/destroyArray`

    return await this._http.delete(requestUrl, {
      data: { itens: payload, integracao: query?.integracao },
    })
  }

  async insertManufacturers(payload) {
    return await this.insert(payload)
  }

  async getImage(query = null, payload) {
    let requestUrl = `/${this.resource}/getImage/${payload}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }
}

export default new ManufacturersIntegrationService()
