import service from '@/services/manufacturers-integration/ManufacturersIntegrationService'

export const manufacturersIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: { selectedManufacturers: [] },
  mutations: {
    ADD_SELECTED_MANUFACTURERS(state, payload) {
      const mappedPayload = payload.map((item) => {
        const obj = {
          NOME: item?.NOME,
          ID: item?.ID,
        }
        const exist = state.selectedManufacturers.find(
          (i) => i.NOME == item?.NOME
        )
        return exist || obj
      })

      state.selectedManufacturers = mappedPayload
    },

    REMOVE_SELECTED_MANUFACTURERS(state, payload) {
      state.selectedManufacturers = state.selectedManufacturers.filter(
        (item) => item.NOME != payload
      )
    },

    CLEAR_SELECTED_MANUFACTURERS(state) {
      state.selectedManufacturers = []
    },
  },
  actions: {
    setSelectedManufacturers({ commit }, payload) {
      commit('ADD_SELECTED_MANUFACTURERS', payload)
    },

    deleteSelectedManufacturer({ commit }, payload) {
      commit('REMOVE_SELECTED_MANUFACTURERS', payload)
    },

    clearSelectedManufacturers({ commit }) {
      commit('CLEAR_SELECTED_MANUFACTURERS')
    },

    async deleteManufacturersIntegration({ rootState, dispatch }, payload) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      }
      const { data } = !Array.isArray(payload)
        ? await service.deleteManufacturers(query, payload)
        : await service.deleteArrayManufacturers(query, payload)
      return data
    },

    async getManufacturersIntegration({ rootState }, q) {
      let query = { ...q }
      query.hibrido = query.hibrido ?? ''
      query.integracao = rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
      let response = await service.getManufacturers(query)
      return response
    },
    async insertManufacturersIntegration({ rootState, dispatch }, payload) {
      try {
        payload.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA

        const response = await service.insertManufacturers(payload)

        if (payload.data?.length == 1) {
          await dispatch(
            'manufacturersModule/setImageForManufacturer',
            {
              NOME: payload.data[0].NOME,
              IMAGEM: payload.image,
              INTEGRADO: 'S',
            },
            {
              root: true,
            }
          )
        } else {
          await dispatch(
            'manufacturersModule/setIntegrationForArray',
            payload.data,
            {
              root: true,
            }
          )
        }
        return response
      } catch (e) {
        return e
      }
    },
    async getImage({ rootState, dispatch }, payload) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      }
      const data = await service.getImage(query, payload)
      await dispatch(
        'manufacturersModule/setImageForManufacturer',
        { NOME: payload, IMAGEM: data, INTEGRADO: 'S' },
        {
          root: true,
        }
      )
      return data
    },
  },
  getters: {
    selectedManufacturersInfo: (state) =>
      state.selectedManufacturers.map((item) => {
        return {
          id: item.ID ?? null,
          name: item.NOME ?? null,
        }
      }),
  },
}
