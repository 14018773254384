import Service from '@/services/Service'

class AsaasService extends Service {
  constructor() {
    super('ASAAS')
    this.resourceBilling = 'billing'
    this.resourceInstallment = 'installments'
    this.integration = 'asaas'
    this.mainResource = 'mbcommerce'
  }

  createRequestUrl(query) {
    let requestUrl = `${this.mainResource}?`

    if (query) query = this.encodeQueryValues(query)

    if (query.todos)
      requestUrl += this.hasParameter(requestUrl)
        ? `&TODOS=${query.todos}`
        : `TODOS=${query.todos}`
    else
      requestUrl += this.hasParameter(requestUrl)
        ? `&page=${query.page}`
        : `page=${query.page}`

    if (query.filtro)
      requestUrl += this.hasParameter(requestUrl)
        ? `&filtro=${query.filtro}`
        : `filtro=${query.filtro}`
    return requestUrl
  }

  async getPayment(payload) {
    const compl = payload.indexOf('pay_') === -1 ? '-order' : ''

    const requestUrl = `${this.mainResource}/${this.resourceBilling}/${
      this.integration
    }${compl}/${encodeURIComponent(payload)}`
    return await this.getAPI(requestUrl)
  }

  async verifyInstallments(payload) {
    let requestUrl = `${this.mainResource}/${this.integration}/${
      this.resourceInstallment
    }?id=${encodeURIComponent(payload.id)}&amount=${encodeURIComponent(
      payload.amount
    )}`
    return await this.getAPI(requestUrl)
  }
}

export default new AsaasService()
