import service from '@/services/billing/AgillitasService'

export const agillitasModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    currencyDefault: 'R$',
    exchangeRate: null,
  },
  mutations: {
    SET_EXCHANGE_RATE(state, payload) {
      state.exchangeRate = parseFloat(
        typeof payload === 'string' ? payload.replace(',', '.') : payload
      )
    },
  },
  actions: {
    async getExchangeRate({ dispatch }) {
      const res = await service.getExchangeRate()
      if (res.success && res.data) dispatch('setExchangeRate', res.data)
      else {
        dispatch('setExchangeRate')
        throw res.message
      }
    },
    async setExchangeRate({ commit }, value = null) {
      await commit('SET_EXCHANGE_RATE', value)
    },
  },
  getters: {
    currencyDefault: (state) => {
      return state.currencyDefault
    },

    exchangeRate: (state, getters, rootState, rootGetters) => {
      const currencies = rootGetters['systemModule/getSystemCurrencies']

      const moedaPadrao = rootGetters['systemModule/getMainCurrency']

      const exchanges = rootGetters['exchangeModule/exchangeRate']

      const exchangeMoedaPadrao = exchanges.find(
        (exchange) => exchange.moeda == moedaPadrao
      ) // 7000

      const currency = currencies.find(
        (currency) => currency.cifrao == state.currencyDefault
      )

      const exchangePix = {
        moeda: currency.index,
        cifrao: currency.cifrao,
        valor: state.exchangeRate ?? 0,
      } // 4,60

      const currencyMoedaPadrao = currencies.find(
        (currency) => currency.index == moedaPadrao
      )

      let amount = {
        ...exchangeMoedaPadrao,
        valorOriginal: exchangePix.valor,
        valorOriginalCurrency: `${exchangePix.cifrao} ${exchangePix.valor}`,
      }
      if (currencyMoedaPadrao.sinal == '/')
        amount.valor = exchangeMoedaPadrao.valor * exchangePix.valor
      else
        amount.valor = parseFloat(
          parseFloat(exchangeMoedaPadrao.valor / exchangePix.valor).toFixed(2)
        )
      return amount
    },

    exchangeOrder: (state, getters, rootState, rootGetters) => {
      const totalCart = rootGetters['productModule/totalCart']
      const exchanges = rootGetters['exchangeModule/exchangeRate']
      const currencies = rootGetters['systemModule/getSystemCurrencies']

      const currencyBilling = currencies.find(
        (currency) => currency.cifrao == 'U$'
      )

      const exchangeCurrencyBilling = exchanges.find(
        (exchange) => exchange.cifrao == 'U$'
      )

      // 5514 * 1549
      let amount = {
        ...exchangeCurrencyBilling,
        billing: exchangeCurrencyBilling.valor,
      }

      if (currencyBilling.sinal == '/')
        amount.valor =
          (totalCart / exchangeCurrencyBilling.valor) *
          getters.exchangeRate.valor
      else
        amount.valor =
          (totalCart * exchangeCurrencyBilling.valor) /
          getters.exchangeRate.valor
      return amount
    },
  },
}
