import service from '@/services/auth/AuthService'

const storageAuthProperty = 'authComp'
let authCompany = JSON.parse(localStorage.getItem(storageAuthProperty)) || ''

export const authCompanyModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    storageAuthProperty,
    authCompany,
  },
  mutations: {
    LOGIN_COMPANY(state, user) {
      state.authCompany = user

      if (localStorage.getItem(storageAuthProperty))
        localStorage.removeItem(storageAuthProperty)
      localStorage.setItem(storageAuthProperty, JSON.stringify(user))
    },
    REQUEST_RELOGIN(state, boolean) {
      state.session.requestLogin = boolean
    },
    UPDATE_AUTH_COMPANY(state, payload) {
      state.authCompany = payload
    },
    SET_TOKEN_INVALID(state, payload) {
      state.tokenInvalid = payload
    },
  },

  actions: {
    async loginCompany({ state, commit }, payload) {
      let userCredentials = ''
      if (!state.authCompany)
        userCredentials = await service.loginCompany(payload)
      else userCredentials = state.authCompany

      const authCompanyResponse = await service.getAuthCompany(
        userCredentials.access_token
      )

      if (authCompanyResponse.data) commit('LOGIN_COMPANY', userCredentials)
    },

    async updateAuthCompany({ commit }, payload) {
      commit('UPDATE_AUTH_COMPANY', payload)
    },

    async checkAuthToken({ dispatch, state }) {
      try {
        let authCompany = state.authCompany

        if (authCompany) {
          return authCompanyResponse
        } else {
          await dispatch('loginUser').then(() => {})
        }
        return state.authCompany
      } catch (err) {
        // dispatch('logoutUser')
      }
    },
    async clearAuthUser({ dispatch }) {
      if (localStorage.getItem(storageAuthProperty))
        await localStorage.removeItem(storageAuthProperty)

      dispatch('updateAuthCompany', null)
      // await commit('SET_SESSION')
    },
    async setTokenInvalid({ commit }, payload) {
      await commit('SET_TOKEN_INVALID', payload)
    },
    async requestRelogin({ commit }, boolean) {
      commit('REQUEST_RELOGIN', boolean)
    },
  },
  getters: {
    getAuthUser: (state) => state.authCompany,
    tokenIsValid: (state) => {
      const payloadToken = state.authCompany.access_token.split('.')[1]
      const data = JSON.parse(atob(payloadToken))
      const expirationDate = new Date(data.exp * 1000)
      const now = new Date()

      return now <= expirationDate
    },
  },
}
