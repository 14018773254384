export const dialogModule = {
    namespaced: true,
    strict: process.env.NODE_ENV !== 'production',
    state: {
        showLoadingDialog: false,
        loadingDialogMessage: '',
    },
    mutations: {
        SHOWLOADINGDIALOG(state, showLoading) {
            state.showLoadingDialog = showLoading
        },
        LOADINGDIALOGMESSAGE(state, message) {
            state.loadingDialogMessage = message
        },
    },
    actions: {
        setShowLoadingDialog({ commit }, showLoading) {
            commit('SHOWLOADINGDIALOG', showLoading)
        },
        setLoadingDialogMessage({ commit }, message) {
            commit('LOADINGDIALOGMESSAGE', message)
        },
        async setDialogMessageAndShow({ commit }, payload) {
            await commit('SHOWLOADINGDIALOG', payload.showLoading ?? false)
            await commit('LOADINGDIALOGMESSAGE', payload.message ?? '')
        },
    },
    getters: {
        getShowLoadingDialog: (state) => state.showLoadingDialog,
        getLoadingDialogMessage: (state) => state.loadingDialogMessage,
    },
}
