import Service from '@/services/Service'

class OrderService extends Service {
  constructor() {
    super('ORDER')
    this.resource = 'mbcommerce/orders'
    this.resourceBilling = 'mbcommerce/billing'
  }

  async getOrders(query) {
    let requestUrl = this.createRequestUrl(query)
    return await this.getAPI(requestUrl)
  }

  async getOrder(payload) {
    const url = this.resource + `/${payload.id}`
    return await this.getAPI(url)
  }

  async getLastOrder(payload) {
    const url = this.resource + `/last-order/${payload}`
    return await this.getAPI(url)
  }

  async getItemsFromOrder(payload) {
    const seq = parseFloat(payload)
    const url = this.resource + `/last-order-itens/${seq}`
    return await this.getAPI(url)
  }

  async getAdminOrder(payload) {
    const requestUrl = `admin/order/${payload}`
    return await this.getAPI(requestUrl)
  }

  async getAdminOrders(query) {
    let requestUrl = 'admin/orders'
    return await this.postAPI(requestUrl, query)
  }

  async sendOrderToFreightHub(query) {
    let requestUrl = 'frenet/send-order'
    return await this.postAPI(requestUrl, query)
  }
}

export default new OrderService()
