import service from '@/services/account/AccountService'

export const accountModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async verify({}, payload) {
      const { status } = await service.vefirifyIfExist(payload)
      return status
    },
    async getAccounts({}, query) {
      const { data } = await service.getAccouts(query)
      return data
    },
  },
  getters: {},
}
