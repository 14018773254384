import Service from '@/services/Service'

class CompanyListService extends Service {
  constructor() {
    super('COMPANYLIST')
    this.resource = 'mbcommerce/company-list'
  }

  createRequestUrl(query) {
    let requestUrl = `${this.resource}?`

    if (query) query = this.encodeQueryValues(query)

    if (query.todos)
      requestUrl += this.hasParameter(requestUrl)
        ? `&TODOS=${query.todos}`
        : `TODOS=${query.todos}`
    else
      requestUrl += this.hasParameter(requestUrl)
        ? `&page=${query.page}`
        : `page=${query.page}`
    if (query.codigo)
      requestUrl += this.hasParameter(requestUrl)
        ? `&CODIGO=${query.codigo}`
        : `CODIGO=${query.codigo}`
    if (query.nome)
      requestUrl += this.hasParameter(requestUrl)
        ? `&NOME=${query.nome}`
        : `NOME=${query.nome}`
    if (query.data)
      requestUrl += this.hasParameter(requestUrl)
        ? `&DATA=${query.data}`
        : `DATA=${query.data}`
    if (query.vendedor)
      requestUrl += this.hasParameter(requestUrl)
        ? `&VENDEDOR=${query.vendedor}`
        : `VENDEDOR=${query.vendedor}`
    if (query.nomeempresa)
      requestUrl += this.hasParameter(requestUrl)
        ? `&NOMEEMPRESA=${query.nomeempresa}`
        : `NOMEEMPRESA=${query.nomeempresa}`
    if (query.filtro)
      requestUrl += this.hasParameter(requestUrl)
        ? `&filtro=${query.filtro}`
        : `filtro=${query.filtro}`
    return requestUrl
  }

  async getCompanyLists(query = null) {
    let requestUrl = this.createRequestUrl(query)
    let response = await this.getAPI(requestUrl)
    return response
  }

  async getCompanyList(sequencialHexa) {
    let requestUrl = `${this.resource}/${encodeURIComponent(sequencialHexa)}`
    return await this.getAPI(requestUrl)
  }
}

export default new CompanyListService()
