import service from '@/services/cities/CityService'

export const cityModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    availableCities: [],
  },
  mutations: {
    SET_CITIES(state, payload) {
      state.availableCities = payload
    },
    PUSH_CITY(state, payload) {
      state.availableCities.push(payload)
    },
    CLEAR_CITIES(state) {
      state.availableCities = []
    },
  },

  actions: {
    async getCities({ commit }, payload) {
      try {
        const { data } = await service.getCities(payload)
        commit('SET_CITIES', data)
      } catch (error) {
        console.error(error)
      }
    },
    pushToCityList({ commit }, payload) {
      commit('PUSH_CITY', payload)
    },
    clearCities({ commit }) {
      commit('CLEAR_CITIES')
    },
  },

  getters: {
    availableCities: (state) => {
      return state.availableCities
    },
  },
}
