import service from '@/services/shipping/ShippingService'

export const shippingModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    feesList: [],
  },
  mutations: {
    SET_FEES_LIST(state, payload) {
      state.feesList = payload
    },
    ADD_TO_FEES_LIST(state, payload) {
      state.feesList.push(payload)
    },

    EDIT_FEES_LIST(state, payload) {
      const idx = state.feesList.findIndex(
        (item) => item.SEQUENCIAL === payload.SEQUENCIAL
      )
      state.feesList[idx] = payload
    },

    REMOVE_FROM_FEES_LIST(state, payload) {
      const idx = state.feesList.findIndex(
        (item) => item.SEQUENCIAL === payload
      )
      state.feesList.splice(idx, 1)
    },
  },
  actions: {
    async getTaxas({ commit }, payload) {
      const { data } = await service.getTaxas()
      commit('SET_FEES_LIST', data)
    },

    async sendTaxa({ commit }, payload) {
      try {
        const { status, id } = await service.insert(payload)
        payload.SEQUENCIAL = id
        commit('ADD_TO_FEES_LIST', payload)
        return status
      } catch (error) {
        console.error(error)
      }
    },

    async getById({}, payload) {
      try {
        const response = await service.getById(payload)
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async delete({ commit }, payload) {
      try {
        const { status } = await service.delete(payload)
        commit('REMOVE_FROM_FEES_LIST', payload)
        return status
      } catch (error) {
        console.error(error)
      }
    },

    async updateTax({ commit }, payload) {
      try {
        const { status } = await service.update(payload, payload.SEQUENCIAL)
        commit('EDIT_FEES_LIST', payload)
        return status
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    feesList: (state) => state.feesList,
  },
}
