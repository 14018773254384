import service from '@/services/exchange/ExchangeService'

export const exchangeModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    exchangeRate: null,
    exchangeOrder: null,
  },
  mutations: {
    SET_EXCHANGE_ORDER(state, value) {
      state.exchangeOrder = value
    },

    SET_EXCHANGE_RATE(state, value) {
      state.exchangeRate = value
    },
  },
  actions: {
    async getExchangeOrder({ commit }, payload) {
      const response = await service.getExchangeOrder(payload)
      commit('SET_EXCHANGE_ORDER', response)
    },

    async getExchangeRate({ commit }, payload) {
      const response = await service.getExchanges(payload)
      commit('SET_EXCHANGE_RATE', response)
    },
  },
  getters: {
    exchangeRate: (state, getters, rootState, rootGetters) => {
      const currencies = rootGetters['systemModule/getSystemCurrencies']

      let exchange = []
      for (let index = 0; index < currencies.length; index++) {
        const currency = currencies[index]
        exchange.push({
          moeda: currency.index,
          cifrao: currency.cifrao,
          valor: state.exchangeRate[`CAMBIO${currency.index}`],
        })
      }
      return exchange
    },

    exchangeOrder: (state) => {
      return state.exchangeOrder
    },
  },
}
