import Service from '../Service'

class CityService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/cities'
  }

  async getCities(query) {
    let requestUrl = this.createRequestUrl(query)
    return await this.getAPI(requestUrl)
  }
}

export default new CityService()
