import service from '@/services/address/AddressService'

const frm = {
  seq: null,
  empresa: null,
  endereco: {
    label: 'Endereço',
    value: '',
  },
  numero: {
    label: 'Número',
    value: '',
  },

  complemento: {
    label: 'Complemento',
    value: '',
  },

  bairro: {
    label: 'Bairro',
    value: '',
  },

  cidade: {
    label: 'Cidade',
    value: '',
  },

  estado: {
    label: 'UF',
    value: '',
  },

  cep: {
    label: 'CEP',
    value: '',
  },

  contato: {
    label: 'Contato',
    value: '',
  },

  foneContato: {
    label: 'Telefone Contato',
    value: null,
  },

  codigoMunicipio: null,
  latitude: null,
  longitude: null,
  isExterior: false,
  isPrincipal: false,
  tipoEndereco: 'Casa',
  tipoEntrega: null,
  pais: 'BRASIL',
  siglaPais: 'BRA',
}

export const addressModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    defaultAddress: JSON.parse(JSON.stringify(frm)),
    addresses: [],
    actualLatAndLng: null,
    actualEndAddrsFromMap: null,
    temporaryAddress: null,
    calculatinLangAndLng: false,
  },
  mutations: {
    SET_ADDRESSES(state, payload) {
      state.addresses = payload
    },

    SET_TEMPORARY_ADDRESS(state, payload) {
      state.temporaryAddress = payload
    },

    SET_ACTUAL_END_ADDRS_FROM_MAP(state, payload) {
      state.actualEndAddrsFromMap = payload
    },

    SET_CALCULATE_LAT_AND_LNG(state, payload) {
      state.calculatinLangAndLng = payload
    },

    SET_ACTUAL_LAT_AND_LNG(state, payload) {
      state.actualLatAndLng = payload
    },
  },
  actions: {
    setActualEndAddrsFromMap({ commit }, payload) {
      commit('SET_ACTUAL_END_ADDRS_FROM_MAP', payload)
    },

    setActualLatAndLng({ commit }, payload) {
      commit('SET_ACTUAL_LAT_AND_LNG', payload)
    },

    setCalculateLangAndLng({ commit }, payload) {
      commit('SET_CALCULATE_LAT_AND_LNG', payload)
    },

    async setTemporaryAddress({ commit, dispatch }, payload) {
      const validPayload = await dispatch('verifyIfHasntAddress', payload)
      if (!validPayload) payload = null
      commit('SET_TEMPORARY_ADDRESS', payload)
    },

    async getAddress(context, payload) {
      return await service.getAddress(payload)
    },
    async getAddresses({ commit }, company) {
      const res = await service.getAddresses(company)
      await commit('SET_ADDRESSES', res)
    },

    async getCityAndDepartamentFromCopany({ commit }, payload) {
      const { data } = await service.getCityAndDepartamentFromCopany(payload)
      return data
    },

    async insertAddress(context, payload) {
      return await service.insert(payload)
    },
    async updateAddress(context, payload) {
      return await service.update(payload, payload.empresa)
    },
    async deleteAddress(context, payload) {
      return await service.deleteAddress(payload)
    },

    verifyIfHasntAddress({ rootState }, payload) {
      let overwriteCpmRegion = true
      if (payload?.empresaEnderecos?.length > 0) return true
      let keys = ['cidade', 'estado', 'endereco']

      if (payload.siglaPais == 'PRY') keys.push('numero', 'codigoMunicipio')
      else keys.push('cep', 'bairro')

      for (let key of keys) {
        if (!payload[key]?.value) {
          overwriteCpmRegion = false
          break
        }
      }

      if (!overwriteCpmRegion && !rootState.regionModule.region)
        overwriteCpmRegion = true

      return overwriteCpmRegion
    },

    // get address from company
    async verifyAddress({ state, dispatch, rootState }, payload) {
      if (payload) {
        let newObj = JSON.parse(JSON.stringify(state.defaultAddress))
        var addressValues = null

        if (payload.seqendereco)
          addressValues = payload.empresaEnderecos.filter(
            (v) => v.seq == payload.seqendereco
          )[0]
        else if (!payload.endereco && payload.empresaEnderecos.length == 1)
          addressValues = payload.empresaEnderecos[0]
        else {
          addressValues = JSON.parse(JSON.stringify(payload))
        }

        Object.keys(newObj).forEach((key) => {
          if (addressValues[key]) {
            if (newObj[key] && newObj[key].hasOwnProperty('value'))
              if (
                addressValues[key] &&
                addressValues[key].hasOwnProperty('value')
              )
                newObj[key].value = addressValues[key].value
              else newObj[key].value = addressValues[key]
            else if (
              addressValues[key] &&
              addressValues[key].hasOwnProperty('value')
            )
              newObj[key] = addressValues[key].value
            else newObj[key] = addressValues[key]
          }
        })

        if (newObj.pais == 'PARAGUAI' || newObj.pais == 'PARAGUAY')
          newObj.siglaPais = 'PRY'
        else if (newObj.pais == 'ARGENTINA') newObj.siglaPais = 'ARG'
        else newObj.siglaPais = 'BRA'

        /** Agora a validação se é um país do exterior é baseado neste campo SIGLAPAIS */
        newObj.isExterior =
          newObj.siglaPais != rootState.systemModule.system.SIGLAPAIS

        await dispatch('regionModule/setRegion', newObj, { root: true })
      }
    },
  },
  getters: {
    getAddresses: (state) => {
      return state.addresses
    },

    getActualEndAddrsFromMap: (state) => {
      return state.actualEndAddrsFromMap
    },

    getActualLatAndLng: (state) => {
      return state.actualLatAndLng
    },

    getCalculateLangAndLng: (state) => {
      return state.calculatinLangAndLng
    },

    frmAddress: (state) => {
      return JSON.parse(JSON.stringify(state.defaultAddress))
    },
  },
}
