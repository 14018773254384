import service from '@/services/auth/AuthService'

const storageAuthProperty = 'authCommerce'
const storageAuthAdminProperty = 'authAdmin'
const storageAuthAdminValue = localStorage.getItem(storageAuthProperty)
let authUser = storageAuthAdminValue ? JSON.parse(storageAuthAdminValue) : ''

let authAdminUser = ''

export const authModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    storageAuthProperty,
    storageAuthAdminProperty,
    authUser,
    authAdminUser,
    env: 'local',
  },
  mutations: {
    LOGIN_USER(state, user) {
      state.authUser = user

      if (localStorage.getItem(storageAuthProperty))
        localStorage.removeItem(storageAuthProperty)
      localStorage.setItem(storageAuthProperty, JSON.stringify(user))
    },

    UPDATE_AUTH_USER(state, payload) {
      state.authUser = payload
    },

    SET_TOKEN_INVALID(state, payload) {
      state.tokenInvalid = payload
    },
  },

  actions: {
    async loginCompany({ state, commit }, payload) {
      let userCredentials = ''
      if (!state.authCompany)
        userCredentials = await service.loginCompany(payload)
      else userCredentials = state.authCompany

      const authCompanyResponse = await service.getAuthCompany(
        userCredentials.access_token
      )

      if (
        authCompanyResponse.data &&
        userCredentials.company == authCompanyResponse.data.user.CODIGO
      )
        commit('LOGIN_USER', userCredentials)
    },

    async updateAuthCompany({ commit }, payload) {
      commit('UPDATE_AUTH_USER', payload)
    },

    async loginUser({ state, commit }, payload) {
      // rota backend já sabe quem é o usuário do ecommerce
      let userCredentials = ''
      if (!state.authUser) {
        let response = await service.loginUser(payload)
        userCredentials = response.data
      } else userCredentials = state.authUser

      const authUserResponse = await service.getAuthUser(
        userCredentials.access_token
      )

      if (authUserResponse.data) commit('LOGIN_USER', userCredentials)
    },

    async updateAuthUser({ commit }, payload) {
      commit('UPDATE_AUTH_USER', payload)
    },

    async checkAuthToken({ state }) {
      try {
        const authUser = state.authUser

        return await service.getAuthCompany(authUser.access_token)
      } catch (err) {
        console.error('erro checkAuthToken (getAuthCompany)')
      }
    },

    async logoutUser({ state, dispatch }) {
      const { access_token } = state.authUser
      const { status, data } = await service.logoutCompany(access_token)

      if (status === 200) await dispatch('clearAuthUser')
      return data
    },

    async clearAuthUser({ dispatch }) {
      if (localStorage.getItem(storageAuthProperty))
        localStorage.removeItem(storageAuthProperty)
      dispatch('companyModule/setCompany', null, { root: true })
      await dispatch('updateAuthCompany', null)
    },

    async setTokenInvalid({ commit }, payload) {
      await commit('SET_TOKEN_INVALID', payload)
    },

    async resetPasswordCompany(context, payload) {
      return await service.resetPasswordCompany(payload)
    },
    async updatePasswordCompany(context, payload) {
      return await service.updatePasswordCompany(payload)
    },
  },
  getters: {
    getAuthUser: (state) => state.authUser,
    tokenInvalid: (state) => {
      return state.tokenInvalid
    },
    tokenExpired: (state) => {
      const payloadToken = state.authUser.access_token.split('.')[1]
      const data = JSON.parse(atob(payloadToken))
      const expirationDate = new Date(data.exp * 1000)
      const now = new Date()

      return now > expirationDate
    },
  },
}
