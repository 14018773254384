import store from '@/store/index'
import AuthAdminService from '@/services/auth-admin/AuthAdminService'

const storageAuthAdminProperty = 'authAdmin'
const storageAuthAdminValue = localStorage.getItem(storageAuthAdminProperty)

let authAdminUser = storageAuthAdminValue
  ? JSON.parse(storageAuthAdminValue)
  : ''

export const authAdminModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    authAdminUser,
    tokenInvalid: false,
    session: {
      requestLogin: false,
    },
  },
  mutations: {
    LOGIN_USER(state, user) {
      state.authAdminUser = user

      if (localStorage.getItem(storageAuthAdminProperty))
        localStorage.removeItem(storageAuthAdminProperty)
      localStorage.setItem(storageAuthAdminProperty, JSON.stringify(user))
    },
    SET_SESSION(state) {
      state.session.requestLogin = false
    },
    UPDATE_AUTH_USER(state, payload) {
      state.authAdminUser = payload
    },
    SET_TOKEN_INVALID(state, payload) {
      state.tokenInvalid = payload
    },
  },
  actions: {
    async loginUser({ commit, dispatch }, user) {
      let service = new AuthAdminService()
      let userLoginResponse = await service.loginUser(user)
      userLoginResponse.data.name = user.NOME

      await dispatch('updateLoginInfo', userLoginResponse.data)
      let authUserResponse = await service.getAuthUser(
        userLoginResponse.data.access_token
      )
      user = { ...userLoginResponse.data, ...authUserResponse.data.user }
      if (userLoginResponse) commit('SET_SESSION')
      return userLoginResponse
    },
    async updateLoginInfo({ dispatch, state, commit }, user) {
      await dispatch('setTokenInvalid', false)
      await commit('LOGIN_USER', user)

      if (state.authAdminUser) {
        if (store.state.systemModule.systemList.length <= 0)
          await store.dispatch('systemModule/getSystems')
        /*
         * recuperar a filial e suas flags
         * Usuário convidado não verifica flags no front
         */
        await store.dispatch(
          'systemModule/getSystem',
          state.authAdminUser.filial
        )
      }
    },
    async checkAuthToken({ state }) {
      try {
        let service = new AuthAdminService()
        let authUser = state.authAdminUser
        let authUserResponse = await service.getAuthUser(authUser.access_token)
        return authUserResponse
      } catch (err) {
        // dispatch('logoutUser')
      }
    },
    async logoutUser({ state, dispatch }) {
      let response = ''
      try {
        let service = new AuthAdminService()
        response = await service.logoutUser()
      } finally {
        /**
         * se ocorrer o erro, limpa tudo para que o usuário tenha possibilidade de acessar em caso de
         * falha na rede ou troca de dominio
         **/
        await dispatch('clearAuthUser')
        await dispatch('setTokenInvalid', false)
      }
      return response
    },
    async clearAuthUser({ dispatch }) {
      if (localStorage.getItem(storageAuthAdminProperty))
        localStorage.removeItem(storageAuthAdminProperty)

      await dispatch('updateAuthUser', null)
    },
    async updateAuthUser({ commit }, payload) {
      commit('UPDATE_AUTH_USER', payload)
    },
    async setTokenInvalid({ commit }, payload) {
      await commit('SET_TOKEN_INVALID', payload)
    },
  },
  getters: {
    getAuthUser: (state) => state.authAdminUser,
    getSession: (state) => state.session,
    getUser: (state) => {
      return state.authAdminUser.name
    },
  },
}
