import service from '@/services/user/UserService'

export const userModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    usersList: [],
    user: {},
    loadingItems: false
  },
  mutations: {
    SET_USERS_LIST(state, payload) {
      state.usersList = payload.data ?? payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_LOADING(state) {
      state.loadingItems = !state.loadingItems
    },
  },
  actions: {
    async getUsersAPI({ commit }, query) {
      try {
        commit('SET_LOADING')
        let response = await service.getUsers(query)
        commit('SET_USERS_LIST', response)
      } finally {
        commit('SET_LOADING')
      }
    },
    async getUserAPI({ commit }, id) {
      try {
        commit('SET_LOADING')
        let response = await service.getUser(id)
        commit('SET_USER', response)
      } finally {
        commit('SET_LOADING')
      }
    },
  },
  getters: {
    getUsersList: (state) => {
      let comboBox = state.usersList.map((user) => {
        return {
          CODIGO: user.CODIGO,
          NOME: user.NOME,
          DESCRICAO: user.CODIGO + ' - ' + user.NOME,
        }
      })
      return comboBox
    },
  },
}
